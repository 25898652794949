import React from "react";


import home2 from "../../../assets/img/home-2-image-2.jpg";
import home3 from "../../../assets/img/home-2-image-1.jpg";

import {
  GiChestnutLeaf,
  GiFruitBowl,
  GiTomato,
  GiFertilizerBag,
} from "react-icons/gi";
import { Link } from "react-router-dom";

import "./ServiceList.css";
function ServiceList() {
  return (
    <>
      <section className="serviceList sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="aboutWooden">
                <span>colors</span>
                <h2>shades of elegance </h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur ad is cing elit, semdo
                  eiusmod tempor est.
                </p>
                <Link to={"/"}>read more</Link>
              </div>
            </div>
            <div className="col-md-5">
              <div className="aboutImg">
                <img src={home2} alt="About" className="img-fluid" />
                <img src={home3} alt="About" className="img-fluid" />
              </div>
            </div>
          </div>
          
        </div>
      </section>
    </>
  );
}

export default ServiceList;
